import router from '@/router'
import store from '@/store'
import { getCountry } from '@/utils/location/phoneCodeCountries'
import useJwt from '@/auth/jwt/useJwt'
import defaultAvatar from '@/assets/images/profile/profile-user.svg'
import viber from '@/assets/images/icons/viber-48.png'
import whatsapp from '@/assets/images/icons/whatsapp-48.png'
import telegram from '@/assets/images/icons/telegram-48.png'
import i18n from '@/libs/i18n'

import { computed } from '@vue/composition-api'
import * as locationService from '@/utils/location/location'
import vueCookie from 'vue-cookie'
import { initialAbility } from '@/libs/acl/config'

export const useUserUi = () => {
  const getAvatar = user => user.avatar || defaultAvatar

  const isUserServiceProvider = user => {
    if (user) {
      if (user.services) {
        return user.services.includes('service_provider')
      }
      if (user.service_offers) {
        return user.service_offers.length
      }
    } else {
      return store.getters['point/offers'].length
    }
    return false
  }

  const getServiceProviderProfileLink = userId => {
    const props = router.resolve({
      name: 'pages-profile-service-provider',
      params: { id: userId },
    })
    return props ? window.location.origin + props.href : ''
  }
  const getManagedUserId = () => {
    const managedUser = store.getters['user/userManaged']
    return managedUser ? managedUser.referral_id : null
  }
  function manageUser(user, route) {
    const redirectTo = route || { name: 'pages-account-setting' }
    useJwt.axiosIns.defaults.headers['Managed-User-id'] = user.referral_id
    store.commit('account/setSwitching', true)
    return store.dispatch('user/setUserManaged', user)
      .then(() => store.dispatch('account/fetchUserCurrent')
        .then(
          // const managedUser = response.data
          // if (managedUser && managedUser.ability) {
          //   const reducedAbilities = managedUser.ability.filter(el => (el.subject !== 'ServiceRequest' || el.action !== 'create'))
          //   ability.update(reducedAbilities)
          // GlobalEventEmitter.$emit('userPermissionsUpdated')
          // }
          response => {
            router.push(redirectTo).catch()
            store.commit('account/setSwitching', false)
            return response
          },

        ))
  }
  const unManageUser = () => {
    store.commit('account/setSwitching', true)
    delete useJwt.axiosIns.defaults.headers['Managed-User-id']
    return store.dispatch('user/setUserManaged', {})
      .then(() => store.dispatch('account/fetchUserCurrent')
        .then(response => {
          const routeFrom = JSON.parse(localStorage.getItem('routeFrom'))
          if (routeFrom && routeFrom.name !== router.currentRoute.name) {
            router.push({ name: routeFrom.name, params: routeFrom.params, query: routeFrom.query }).then(() => store.commit('user/unsetUserManaged')).catch()
          } else {
            store.commit('user/unsetUserManaged')
          }
          store.commit('account/setSwitching', false)
          return response
        }))
  }

  const isManagedUser = user => (user && user.name !== 'Deleted' ? user.managed : getManagedUserId())

  const setLatestUserRoute = () => {
    const { currentRoute } = router

    const data = JSON.stringify({ name: currentRoute.name, params: currentRoute.params, query: currentRoute.query })
    localStorage.setItem('routeFrom', data)
  }

  async function switchToUser(user, route) {
    if (user) {
      setLatestUserRoute()
      return new Promise((resolve, reject) => {
        manageUser(user, route)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    return unManageUser()
  }

  const handleUserViewActionClick = user => {
    const route = router.resolve({ name: 'pages-profile-service-provider', params: { id: user.id } })
    window.open(route.href, '_blank')
  }

  const fetchMessengers = () => store.dispatch('appOptions/fetchMessengers').then(response => {
    const successStatuses = [200, 201]
    if (successStatuses.includes(response.status)) {
      const messengers = response.data.data
      messengers.forEach(el => {
        if (el.slug === 'viber' && !el.icon) {
          el.icon = viber
        }
        if (el.slug === 'telegram' && !el.icon) {
          el.icon = telegram
        }
        if (el.slug === 'whatsapp' && !el.icon) {
          el.icon = whatsapp
        }
      })
      store.commit('appOptions/INIT_MESSENGERS_LIST', messengers)
    }
  })

  const optionMessengers = computed(() => {
    const messengers = store.getters['appOptions/messengers']
    if (!messengers.length) {
      fetchMessengers().then(() => messengers)
    }
    return messengers
  })
  function logout() {
    this.$store.commit('account/clearUser')
    // Remove userData from localStorage
    localStorage.removeItem('userData')
    // Remove tokens
    vueCookie.delete(useJwt.jwtConfig.storageTokenKeyName)
    vueCookie.delete(useJwt.jwtConfig.storageRefreshTokenKeyName)
    // Reset ability
    this.$ability.update(initialAbility)
    // Redirect to login page
    this.$router.push({ name: 'auth-login' })
  }
  return {
    isUserServiceProvider,
    getServiceProviderProfileLink,
    switchToUser,
    getManagedUserId,
    isManagedUser,
    handleUserViewActionClick,
    getAvatar,
    optionMessengers,
    logout,
  }
}

export const useUserLocation = () => {
  const getUserGeoData = computed(() => store.getters['user/geoData'])

  const userCountryCode = () => {
    const geoData = getUserGeoData.value
    if (geoData) {
      return geoData.country_code
    }
    if (localStorage.getItem('country_code')) {
      return localStorage.getItem('country_code')
    }
    return 'UA'
  }

  const getUserCountry = () => {
    const country = getCountry(userCountryCode())
    return country ? country.name : ''
  }

  const getUserLat = computed(() => {
    const geoData = getUserGeoData.value
    return geoData.lat
  })

  const getUserLng = computed(() => {
    const geoData = getUserGeoData.value
    return geoData.lon
  })

  const updateUserGeoData = geoData => {
    store.commit('user/updateGeoData', geoData)
  }

  const transformFetchedLocality = location => {
    let locality = null
    if ('city' in location.address) {
      locality = location.address.city
    }
    if ('town' in location.address) {
      locality = location.address.town
    }
    if ('village' in location.address) {
      locality = location.address.village
    }
    return locality
  }

  const getUserAddressFormattedShort = computed(() => {
    const geoData = getUserGeoData.value

    const address = []
    if (geoData.address) {
      if (geoData.address.country) {
        address.push(geoData.address.country.toUpperCase())
      }
      if (geoData.address.province) {
        address.push(geoData.address.province)
      }

      address.push(transformFetchedLocality(geoData))
    }
    return address.join(', ')
  })

  // const setUserCountryData = async () => {
  //   store.dispatch('user/fetchGeoData')
  //     .then(response => {
  //       if (response.status === 200) {
  //         const location = await locationService.locationByCoordinates({ latitude: lat, longitude: lng }, this.$i18n.locale)
  //         let countryCode = response.data.data.country_code
  //         if (countryCode) {
  //           const isDialCodeAvailable = code => countriesIso.includes(code)
  //           // we need to check if dial Code is available to be sure
  //           // that phone number input field (package: vue-phone-number-input) will be displayed correctly
  //           countryCode = isDialCodeAvailable(countryCode) ? countryCode : 'UA'
  //           localStorage.setItem('countryCode', countryCode)
  //         }
  //
  //         const currencyCode = localStorage.getItem('currencyCode')
  //         if (!currencyCode) {
  //           localStorage.setItem('currencyCode', response.data.data.currency)
  //         }
  //       }
  //     })
  // }
  const setUserCountryData = async () => {
    store.dispatch('user/fetchGeoData')
      .then(response => {
        if (response.status === 200) {
          const { lat } = response.data.data
          const lng = response.data.data.lon
          return locationService.locationByCoordinates({ latitude: lat, longitude: lng }, i18n.locale).then(geoData => {
            updateUserGeoData(geoData)
          })
        }
      })
  }
  const updateUserCountryData = async () => locationService.currentLocation(i18n.locale).then(geoData => {
    updateUserGeoData(geoData)
  })

  return {
    userCountryCode,
    getUserLat,
    getUserLng,
    updateUserGeoData,
    getUserAddressFormattedShort,
    setUserCountryData,
    updateUserCountryData,
  }
}
