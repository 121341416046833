class Location {
  static get modelName() {
    return 'Location'
  }

  constructor(location) {
    Object.assign(this, location)
  }
}

export default Location
