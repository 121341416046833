import axios from '@axios'

const ENDPOINT = 'https://nominatim.openstreetmap.org'
const FORMAT = 'jsonv2'

export function getFormattedAddress(address) {
  const addr = [
    address.road,
    address.house_number,
    address.locality,
    address.state,
    address.country,
  ]
  if (address.apartment_number) {
    const apartmentNumber = address.apartment_number.replace(/.+/g, 'apt. $&')
    addr.splice(2, 0, apartmentNumber)
  }
  return addr.filter(Boolean).join(', ')
}

export function currentCoordinates() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => resolve(coords),
      // Reject if the user doesn't
      // allow accessing their location.
      error => reject(error),
    )
  })
}
export function getSearchQueryParams(address, locale, structured) {
  if (structured) {
    const params = {
      format: FORMAT,
      state: address.state,
      country: address.country,
      addressdetails: 1,
      'accept-language': locale,
    }
    if (address.locality !== '') {
      params.city = address.locality
    }
    if (address.road !== '') {
      params.street = `${address.road}`
    }
    if (address.house_number !== '' && address.road !== '') {
      params.street = `${address.house_number} ${address.road}`
    }

    return params
  }
  const formattedAddress = [
    address.house_number,
    address.road,
    address.locality,
    address.state,
  ]

  return {
    format: FORMAT,
    q: formattedAddress.filter(Boolean).join(', '),
    addressdetails: 1,
    'accept-language': locale,
  }
}

export async function locationBySearchQuery(address, locale, structured) {
  const params = getSearchQueryParams(address, locale, structured)
  const { data } = await axios.get(`${ENDPOINT}/search`, {
    params,
  })
  return data
}

export async function locationByCoordinates({ latitude, longitude }, locale) {
  const { data } = await axios.get(`${ENDPOINT}/reverse`, {
    params: {
      format: FORMAT,
      lat: latitude,
      lon: longitude,
      'accept-language': locale,
    },
  })
  return data
}

export async function currentLocation(locale) {
  const coordinates = await currentCoordinates()
  return locationByCoordinates(coordinates, locale)
}
